import React from 'react';
import { ExternalLink, Heading } from '../../components';

import renderMarkdown from '../PageBuilder/markdownProcessor';
import css from './ListingPage.module.css';

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>
        {renderMarkdown(text, {
          a: ExternalLink,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionTextMaybe;
