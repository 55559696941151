import React from 'react';
import moment from 'moment';
import { array, func, object } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { Heading } from '../../components';

import { isFieldForListingType } from '../../util/fieldHelpers';
import { SCHEMA_TYPE_BOOLEAN, SCHEMA_TYPE_ENUM, SCHEMA_TYPE_LONG } from '../../util/types';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const {
    publicData,
    metadata = {},
    listingConfig,
    listingFieldConfigs,
    isFieldForCategory,
    intl,
  } = props;
  const { listingFieldsToRender } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, label, options = [] } = config;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (typeof value !== 'undefined') {
      if (options.length) {
        const optionConfig = options.find(o => o.key === value);
        return filteredConfigs.concat({ key, value: optionConfig?.label, label });
      }
      if (key === 'createdAt') {
        return filteredConfigs.concat({ key, value: moment(publicDataValue).format('LL'), label });
      }
      return filteredConfigs.concat({ key, value, label });
    }

    return filteredConfigs;
  };

  const pickListingFieldsConsole = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const { listingType } = publicData;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      if (schemaType === SCHEMA_TYPE_ENUM) {
        return filteredConfigs.concat({ key, value: optionConfig?.label, label });
      }
      if (schemaType === SCHEMA_TYPE_BOOLEAN) {
        return filteredConfigs.concat({ key, value: getBooleanMessage(value), label });
      }
      if (schemaType === SCHEMA_TYPE_LONG) {
        return filteredConfigs.concat({ key, value, label });
      }
    }
    return filteredConfigs;
  };

  const listingFieldFromConsole = listingFieldConfigs.reduce(pickListingFieldsConsole, []);

  const existingListingFields = listingFieldsToRender.reduce(
    pickListingFields,
    listingFieldFromConsole
  );

  return existingListingFields.length > 0 ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {existingListingFields.map(detail => (
          <li key={detail.key} className={css.detailsRow}>
            <span className={css.detailLabel}>{detail.label}</span>
            <span>{detail.value}</span>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

SectionDetailsMaybe.defaultProps = {
  publicData: null,
  metadata: null,
};

SectionDetailsMaybe.propTypes = {
  publicData: object,
  metadata: object,
  listingConfig: object.isRequired,
  listingFieldConfigs: array.isRequired,
  isFieldForCategory: func.isRequired,
  intl: intlShape.isRequired,
};

export default SectionDetailsMaybe;
